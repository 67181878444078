import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import Page from "../../components/page";
import { ACCOUNT_SETTINGS } from "../../utils/constant";
import { detectDevice } from "../../utils/helper";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getSubscriptionPlanDetails } from "../../redux/services/subscription";
import { useAppSelector } from "../../hooks/useAppSelector";
import { clearPaymentSummary } from "../../redux/slices/subscriptionSlice";
import { fetchTokenData } from "../../redux/services/common";

const Subscription = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { userDetails } = useAppSelector(state => state.common);
  const { companyId } = userDetails?.data?.company || { companyId: 0 };

  useEffect(() => {
    if (detectDevice()?.type === "Desktop") {
      dispatch(fetchTokenData());
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSubscriptionPlanDetails());
    dispatch(clearPaymentSummary());
  }, [dispatch]);

  return (
    <Page
      title={[]}
      className="subscription-page"
    >
      <div className="flex alignStart justifyBetween">
        <div className="subscription-settings-header">
          <h3 className="title-3">{ACCOUNT_SETTINGS.SUBSCRIPTION_PAGE.TITLE}</h3>
          <p className="caption">{ACCOUNT_SETTINGS.SUBSCRIPTION_PAGE.DESCRIPTION}</p>
        </div>
        <button className="rounded-14 support-button flex alignCenter gap-2" onClick={() => navigate(`/settings/${companyId}/gethelp`)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M1.07812 12.96V12.9656C1.08179 13.4074 1.23007 13.8357 1.50029 14.1852C1.77051 14.5346 2.14778 14.7859 2.57437 14.9006C2.77125 16.3294 3.98625 17.4375 5.47125 17.4375H8.99813C9.3075 17.4375 9.56063 17.1844 9.56063 16.875C9.56063 16.5656 9.3075 16.3125 8.99813 16.3125H5.47125C4.59937 16.3125 3.87375 15.6938 3.69937 14.8781C4.515 14.6194 5.11688 13.86 5.11688 12.96V9.73687C5.11688 8.62313 4.21125 7.7175 3.0975 7.7175C2.78813 7.7175 2.49563 7.79625 2.23125 7.92C2.51813 4.43812 5.44312 1.6875 8.99813 1.6875C10.8094 1.6875 12.5138 2.39625 13.8019 3.67875C14.9494 4.83187 15.6356 6.3225 15.765 7.92562C15.5006 7.79625 15.2081 7.7175 14.8988 7.7175C13.785 7.7175 12.8794 8.62313 12.8794 9.73687V12.96C12.8794 14.0738 13.785 14.9794 14.8988 14.9794C16.0069 14.9794 16.9125 14.0737 16.9181 12.9656V8.4825C16.9181 6.37313 16.0913 4.38188 14.595 2.88C13.0931 1.38375 11.1075 0.5625 8.99813 0.5625C4.63312 0.5625 1.07812 4.11188 1.07812 8.4825V12.96Z" fill="black" />
          </svg>
          {ACCOUNT_SETTINGS.SUBSCRIPTION_PAGE.SUPPORT_BUTTON}
        </button>
      </div>
      <Outlet />
    </Page>
  );
};

export default Subscription;