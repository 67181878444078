import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { message } from "antd";

import { Button } from "../../components";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getBillingDetailsData, getPaymentDetailsSummary } from "../../redux/services/subscription";
import { useAppSelector } from "../../hooks/useAppSelector";
import { ERROR_STRINGS } from "../../utils/constant";
import { formatRupees } from "../../utils/helper";

const SubscriptionDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { billingDetails } = useAppSelector((state) => state.subscription);
  console.log(billingDetails?.data);
  const gotoPlans = () => {
    navigate("plans");
  };

  useEffect(() => {
    dispatch(getBillingDetailsData());
  }, [dispatch]);

  const handleRenewPlan = (planId: number) => {
    dispatch(getPaymentDetailsSummary({
      id: planId,
      payload: {
        needGSTInvoice: false,
      },
    })).then((result) => {
      if (result?.payload?.status === 200) {
        navigate(`plans/${billingDetails?.data?.plan?.planId}`);
      } else {
        message.error(ERROR_STRINGS.SOMETHING_WRONG);
      }
    }).catch((error) => {
      message.error(ERROR_STRINGS.SOMETHING_WRONG);
    });
  };

  return (
    billingDetails?.isLoading ? <></> : <div className="subscription-details-container">
      {billingDetails?.data?.plan?.isActive ? <></> : <div className="message-toaster flex justifyBetween">
        <div className="flex alignCenter gap-2">
          <div className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#F5222D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <div className="message-text">
            <span className="title">Your plan expired on</span>
            <span className="date">{dayjs(billingDetails?.data?.plan?.expiredAt)?.format("D MMM, YYYY")}!</span>
          </div>
        </div>
        <Button type="button" className="success" onClick={() => handleRenewPlan(billingDetails?.data?.plan?.planId)}>
          Renew Now
        </Button>
      </div>}
      <section className="flex alignStart justifyBetween gap-8 plan-details-section">
        <div className="left-section">
          <div className="header-container flex justifyBetween alignCenter">
            <div className="flex alignCenter gap-2">
              <h3 className="title-3">
                Current Plan
              </h3>
              <span className="joining-date">
                Member since {dayjs(billingDetails?.data?.plan?.createdOn)?.format("DD MMM, YYYY")}
              </span>
            </div>
            <Button type="button" className="outline rounded-14" onClick={gotoPlans}>View Benefits</Button>
          </div>
          <div className="body-container flex justifyBetween alignStart gap-2">
            <div className="plan flex gap-2 alignStart">
              <img src={billingDetails?.data?.plan?.planImage} alt="planImage" />
              <div className="plan-data">
                <h3 className="title-3">{billingDetails?.data?.plan?.planName}</h3>
                <p className="caption">{billingDetails?.data?.plan?.supportiveText}</p>
              </div>
            </div>
            <div className="flex direction-column alignEnd plan-pricing-details">
              <span className="price">
                {billingDetails?.data?.plan?.price}<span className="small-text">/year</span>
              </span>
              <p className={billingDetails?.data?.plan?.isActive ? "next-plan-date" : "next-plan-date error"}>
                Next Payment {dayjs(billingDetails?.data?.plan?.expiredAt)?.format("D MMM, YYYY")}
              </p>
            </div>
          </div>
          <div className="buttons-container flex justifyBetween gap-4 w-100">
            {billingDetails?.data?.plan?.isActive ?
              <>
                <Button type="button" className="outlined success" disabled>Currently Active</Button>
                {(billingDetails?.data?.plan?.planId < 3) ?
                  <Button type="button" className="primary w-100" onClick={gotoPlans}>Upgrade to Premium</Button> : <></>}
              </>
              :
              <>
                <Button type="button" className="outlined error">Expired</Button>
                {(billingDetails?.data?.plan?.planId < 3) ?
                  <Button type="button" className="primary w-100" onClick={gotoPlans}>Upgrade to Premium</Button> : <></>}
              </>
            }
          </div>
        </div>
        <div className="right-section">
          <h3 className="section-title">Billing Details</h3>
          <div className="section-body">
            <h3 className="company-title">{billingDetails?.data?.billing?.name}</h3>
            <p className="caption address-line">{billingDetails?.data?.billing?.address}</p>
            <p className="gst-number">{billingDetails?.data?.billing?.gstNumber ? `GST - ${billingDetails?.data?.billing?.gstNumber}` : ""}</p>
            <p className="pan-number">{billingDetails?.data?.billing?.pan ? `PAN - ${billingDetails?.data?.billing?.pan}` : ""}</p>
          </div>
        </div>
      </section>
      {billingDetails?.data?.invoiceHistory?.length ? <section className="invoice-details-section">
        <h2 className="title-2">Billing History</h2>
        <p className="caption">Invoices are generated every 24 hours, with transactions consolidated into a single invoice during this period.</p>
        <div className="invoice-table rounded-12 w-100">
          <div className="table-header w-100">
            <div className="table-row flex w-100 justifyBetween">
              <div className="table-head">Date</div>
              <div className="table-head">Invoice</div>
              <div className="table-head">Plan</div>
              <div className="table-head">Amount</div>
              <div className="table-head"></div>
            </div>
          </div>
          <div className="table-body w-100">
            {billingDetails?.data?.invoiceHistory?.map((item: any, index: number) => {
              return (
                <div className="table-row flex w-100 justifyBetween" key={index}>
                  <div className="table-data">{dayjs(item?.createdOn)?.format("DD MMM, YYYY")}</div>
                  <div className="table-data"><Link to={item?.invoiceUrl} target="_blank">{item?.invoiceNumber}</Link></div>
                  <div className="table-data">Gold</div>
                  <div className="table-data">{formatRupees(item?.paymentHistory?.grandTotal, true, false)}</div>
                  <div className="table-data"><Link className="button" to={item?.invoiceUrl} target="_blank" download={true}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <g clipPath="url(#clip0_13607_37763)">
                        <path d="M8 5.33203L8 10.6654M8 10.6654L10 8.66536M8 10.6654L6 8.66536" stroke="#1C274C" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.66671 2.22391C5.64729 1.65668 6.78575 1.33203 8.00004 1.33203C11.6819 1.33203 14.6667 4.3168 14.6667 7.9987C14.6667 11.6806 11.6819 14.6654 8.00004 14.6654C4.31814 14.6654 1.33337 11.6806 1.33337 7.9987C1.33337 6.78441 1.65802 5.64594 2.22526 4.66536" stroke="#1C274C" strokeWidth="1.2" strokeLinecap="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_13607_37763">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    Download
                  </Link></div>
                </div>
              );
            })}
          </div>
        </div>
      </section> : <></>}
    </div>
  );
};

export default SubscriptionDetails;