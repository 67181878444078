import { SettingOutlinedIcon } from "./settingOutlinedIcon";
import { SearchOutlinedIcon } from "./searchOutlinedIcon";
import { BellOutlinedIcon } from "./bellOutlinedIcon";
import LogoIcon from "./logoIcon.png";
import ProfileIcon from "./profileIcon.png";
import Logo from "./logoIcon";
import HomeIcon from "./homeIcon";
import SearchIcon from "./searchIcon";
import OfferBG from "./offerBG";
import MainLogo from "./logo";
import SecureIcon from "./secureIcon";
import IndiaFlagIcon from "./indiaFlagIcon";
import VerifiedIcon from "./verifiedIcon";
import CrossRoundedIcon from "./crossRoundedIcon";
import StatusIcon from "./statusIcon";
import DeleteOutlinedIcon from "./DeleteOutlinedIcon";
import UploadOutlinedIcon from "./UploadOutlinedIcon";
import CrossIcon from "./crossIcon";
import MoreIconsDots from "./moreIconsDots";
import RoundClockIcon from "./roundClockIcon";
import StopIcon from "./stopIcon";
import CartBagIcon from "./cartBag";
import LocationIcon from "./locationIcon";
import NoUser from "./NoUser";
import NoDataIcon from "./Search.png";
import ProductsGroupIcon from "./productsGroup";
import productPlaceholderImage from "./productPlaceholderImage.jpg";
import PlusIcons from "./plusIcons";
import MinusIcon from "./minusIcon";
import CartIcon from "./cartIcon";
import SquareLogo from "./app-logo.png";
import HorizontalLogo from "./web-logo.png";
import BiizlineLogo from "./biizlineLogo.png";
import EmptyStateSpecialPriceList from "./emptyStateSpecialPriceList.png";
import AppQRCode from "./qrcode.png";
import SuccessIcon from "./wp_success.png";
import { CheckMarkIcon } from "./checkMarkIcon";
import PhoneOutlinedIcon from "./phoneOutlinedIcon";
import EmailOutlinedIcon from "./emailOutlinedIcon";
import PromoCodeIcon from "./promoCodeIcon";
import CrossIcon2 from "./crossIcon2";
import SubscriptionPoster from "./subscribe-poster.svg";

export {
  SettingOutlinedIcon,
  SearchOutlinedIcon,
  BellOutlinedIcon,
  Logo,
  HomeIcon,
  SearchIcon,
  OfferBG,
  MainLogo,
  SecureIcon,
  IndiaFlagIcon,
  VerifiedIcon,
  CrossRoundedIcon,
  CrossIcon,
  LogoIcon,
  ProfileIcon,
  StatusIcon,
  DeleteOutlinedIcon,
  UploadOutlinedIcon,
  MoreIconsDots,
  RoundClockIcon,
  StopIcon,
  CartBagIcon,
  LocationIcon,
  NoUser,
  NoDataIcon,
  ProductsGroupIcon,
  productPlaceholderImage,
  PlusIcons,
  MinusIcon,
  CartIcon,
  SquareLogo,
  HorizontalLogo,
  BiizlineLogo,
  EmptyStateSpecialPriceList,
  AppQRCode,
  SuccessIcon,
  CheckMarkIcon,
  PhoneOutlinedIcon,
  EmailOutlinedIcon,
  PromoCodeIcon,
  CrossIcon2,
  SubscriptionPoster,
};