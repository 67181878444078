// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'SF Pro Text';
  src: url('https://bizline-staging-bucket.s3.ap-south-1.amazonaws.com/assets/fonts/SFPro/SF-Pro-Text-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('https://bizline-staging-bucket.s3.ap-south-1.amazonaws.com/assets/fonts/SFPro/SF-Pro-Text-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('https://bizline-staging-bucket.s3.ap-south-1.amazonaws.com/assets/fonts/SFPro/SF-Pro-Text-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('https://bizline-staging-bucket.s3.ap-south-1.amazonaws.com/assets/fonts/SFPro/SF-Pro-Text-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('https://bizline-staging-bucket.s3.ap-south-1.amazonaws.com/assets/fonts/SFPro/SF-Pro-Text-Heavy.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,oIAAoI;EACpI,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;EAC1B,mIAAmI;EACnI,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;EAC1B,qIAAqI;EACrI,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;EAC1B,iIAAiI;EACjI,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;EAC1B,kIAAkI;EAClI,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":["@font-face {\n  font-family: 'SF Pro Text';\n  src: url('https://bizline-staging-bucket.s3.ap-south-1.amazonaws.com/assets/fonts/SFPro/SF-Pro-Text-Regular.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'SF Pro Text';\n  src: url('https://bizline-staging-bucket.s3.ap-south-1.amazonaws.com/assets/fonts/SFPro/SF-Pro-Text-Medium.ttf') format('truetype');\n  font-weight: 500;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'SF Pro Text';\n  src: url('https://bizline-staging-bucket.s3.ap-south-1.amazonaws.com/assets/fonts/SFPro/SF-Pro-Text-SemiBold.ttf') format('truetype');\n  font-weight: 600;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'SF Pro Text';\n  src: url('https://bizline-staging-bucket.s3.ap-south-1.amazonaws.com/assets/fonts/SFPro/SF-Pro-Text-Bold.ttf') format('truetype');\n  font-weight: 700;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'SF Pro Text';\n  src: url('https://bizline-staging-bucket.s3.ap-south-1.amazonaws.com/assets/fonts/SFPro/SF-Pro-Text-Heavy.ttf') format('truetype');\n  font-weight: 800;\n  font-style: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
