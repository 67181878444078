import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";

import endPoint from "./endPoints.json";
import axiosInstance from "../axios";

export const getSubscriptionPlanDetails = createAsyncThunk("subscription/getSubscriptionPlanDetails", async (args, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(endPoint.getSubscriptionPlans);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

interface CreatePaymentProps {
  paymentData: {
    subtotal: string;
    grandTotal: string;
    promocodeDiscount: string;
    subscriptionDiscount: string;
    otherDiscount: string;
    CGSTInRupees: string;
    CGSTInpercentage: string;
    SGSTInRupees: string;
    SGSTInpercentage: string;
    IGSTInRupees: string;
    IGSTInpercentage: string;
    needGSTInvoice: boolean;
    gstNumber?: number;
  } | undefined,
  userData: {
    firstName: string,
    lastName: string,
    phoneNo: string,
    email: string,
  },
}

export const createSubscriptionPayment = createAsyncThunk("subscription/createPayment", async (args: { id: number, payload: CreatePaymentProps }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(`${endPoint.createPayment}/${args.id}`, args.payload);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

interface GetPaymentDetailsSummary {
  promoCode?: string;
  needGSTInvoice: boolean;
  gstNumber?: string;
}

export const getPaymentDetailsSummary = createAsyncThunk("subscription/getSubscriptionBillingDetailSummary", async (args: { id: number, payload: GetPaymentDetailsSummary }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(`${endPoint.getSubscriptionBillingDetailSummary}/${args.id}`, args.payload);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

interface CheckPromoCodeProps {
  promocode: string;
}

export const checkPromoCode = createAsyncThunk("subscription/verifyPromoCode", async (payload: CheckPromoCodeProps, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoint.verifyPromoCode, payload);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

interface CheckGSTCodeProps {
  GST: string;
}

export const checkGSTCode = createAsyncThunk("subscription/verifyGST", async (payload: CheckGSTCodeProps, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoint.verifyGST, payload);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

interface VerifyRazorpayPaymentProps {
  orderId: string,
  paymentId: string,
  signature: string,
}

export const verifyRazorpayPayment = createAsyncThunk("subscription/verifyPayment", async (payload: VerifyRazorpayPaymentProps, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoint.verifyPayment, payload);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getSubscriptionPayloadData = createAsyncThunk("subscription/getSubscriptionPayload", async (payload: { module: string }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoint.getSubscriptionPayload, payload);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getPlateformSubscriptionPayloadData = createAsyncThunk("subscription/getPlateformSubscriptionPayloadData", async (payload: { module: string }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoint.getSubscriptionPayload, payload);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getBillingDetailsData = createAsyncThunk("subscription/getBillingDetails", async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(endPoint.getBillingDetails);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});