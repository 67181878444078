import React from "react";

const Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="30" viewBox="0 0 1024 1024" fill="none">
      <rect width="1024" height="1024" rx="204.8" fill="#FFBC0D" />
      <rect x="343" y="250.875" width="143.36" height="143.36" rx="71.68" fill="white" />
      <path d="M414.8 320.3C414.8 301.743 429.843 286.7 448.4 286.7C466.957 286.7 482 301.743 482 320.3C482 338.857 466.957 353.9 448.4 353.9C429.843 353.9 414.8 338.857 414.8 320.3Z" fill="black" />
      <rect x="368.6" y="404.475" width="92.16" height="368.64" rx="46.08" fill="black" />
      <rect width="143.36" height="143.36" rx="71.68" transform="matrix(-1 0 0 1 680.92 250.875)" fill="white" />
      <path d="M609.12 320.3C609.12 301.743 594.077 286.7 575.52 286.7C556.963 286.7 541.92 301.743 541.92 320.3C541.92 338.857 556.963 353.9 575.52 353.9C594.077 353.9 609.12 338.857 609.12 320.3Z" fill="black" />
      <rect x="563.16" y="404.475" width="92.16" height="368.64" rx="46.08" fill="black" />
    </svg>
  );
};

export default Logo;